import { Component, ContentChildren, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { FeaturesService } from 'src/app/Services/features.service';
import { DeleteCheckComponent } from '../delete-check/delete-check.component';

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {

  public Breadcrumbs: string[][] = [];
  @Input()
  public Title: string = "";

  @Input()
  public ModelId?: any;

  // Introduce this for page that has form which marks as dirty during init, such as Parking levels
  @Input()
  public Form: FormGroup | null = null;

  @Input()
  public EnableEdit = false;

  @Input()
  public EnableDelete = false;

  @Input()
  public DeleteUrl?: string;

  @Input()
  public DeleteCheck = true;

  @Input()
  public FormSaveEvent?: Observable<void>;

  // this is a special parameter to handle something like parking level which embedded inside a parking lot
  // Once we have a parking level deteled, we need to nav back to the parking level
  @Input()
  public DeleteNavLink?: string | null;


  public editRouterLink?: string;
  public displayTitle!: string;


  constructor(private router: Router, private featureService: FeaturesService, private modalService: SimpleModalService) { }


  ngOnInit(): void {
    console.log("input title is " + this.Title);
    this.displayTitle = this.Title;

    this.BuildBreadcrumbs();

    this.featureService.MenuChanged.subscribe(() => {
      this.BuildBreadcrumbs();
    })

    if (this.EnableEdit) {
      let urlParts = this.router.url.split('/');
      urlParts.splice(urlParts.length-1, 0, 'edit');
      this.editRouterLink = urlParts.join('/');
    }
    if (this.EnableDelete) {

    }

    if (this.ModelId == null) {
      this.EnableDelete = false;
    }
  }


  public Delete() {
    if (this.Form != null) {
      this.Form.markAsPristine();
    }
    this.modalService.addModal(DeleteCheckComponent, { name: this.Title, url: this.DeleteUrl, modalId: this.ModelId, deleteCheck: this.DeleteCheck, deleteNavLink: this.DeleteNavLink })
      .subscribe((result) => {
        console.log(result);
      });
  }

  private async BuildBreadcrumbs() {
    let parts = this.router.url.substring(1).split('/');
    let builder = parts[0];
    this.Breadcrumbs = [];
    let preRoute = "";
    for (let i = 0; i < parts.length; i++) {


      let menuItem = await this.featureService.FindMenuItemByRoute(builder);

      if (menuItem != null) {
        if (i == parts.length - 1 && menuItem.Text == this.Title) {
          //same name as the title so don't repeat
          continue;
        }
        console.log("Push 1 : " + menuItem.Text);
        console.log("Link 2:" + menuItem.Route);

        this.Breadcrumbs.push([menuItem.Text, "/" + menuItem.Route]);
        preRoute = menuItem.Route;
      }
      else {
        // path not found, could be
        // 1: Details Page - Check if anything follows, yes - not details page
        //    Details page - do nothing
        // 2: Edit Page - Check if 'Edit" follows, yes - edit page ; no - not edit page
        //    Edit page - Add current page to
        if (i + 2 == parts.length) {
          // do  nothing, this might be a detail page route
          let curPart = parts[i];
          let nextPart = parts[i + 1];
          if (curPart != null) {

            if (curPart.toLowerCase().includes('edit')) {
              // this is a edit page. change the title 
              this.displayTitle = "Edit " + this.Title;
              // get the next part which should contains the id and the name
              let nextPart = parts[i + 1];
              // get fragment from the next part
              let fragments = nextPart.split('#');
              if (fragments.length == 2) {
                // this is what we expected
                this.Breadcrumbs.push([decodeURIComponent(fragments[1]), "/" + preRoute + '/' + fragments[0]]);
              }
            }
          }
        }
        else if (i + 2 > parts.length) {
          // do  nothing, this might be a detail page route

        }

      }
      if (i < parts.length) {
        preRoute = builder;
        builder = builder + '/' + parts[i + 1];
      }

    }
  }
}
